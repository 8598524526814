// THIS SHOULD BE REPLACED WITH HUI TEXTBOX ONCE THE FIXES HAVE BEEN TESTED!!!!!!!!! SP
import React from 'react'

interface InputProps {
  value: string
  placeholder: string
  ariaLabel: string
  disabled?: boolean
  onChange: (value: string) => void
}

export const Input: React.FC<InputProps> = ({ value, placeholder, ariaLabel, disabled, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value)
  }

  return (
    <div aria-busy='false' className='text compact active' style={{ width: '100%' }}>
      <input
        id=''
        name='form-control'
        className='form-control'
        placeholder={placeholder}
        type='text'
        aria-label={ariaLabel}
        onChange={handleChange}
        value={value}
        disabled={disabled}
      />
    </div>
  )
}
