import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import 'Scss/main.scss'
import { StoreProvider } from 'easy-peasy'

import { store } from 'Store'
import { Initialization } from 'Components/Initialization'

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <StoreProvider store={store}>
        <Initialization />
      </StoreProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById('root')
)
