import { Action, action } from 'easy-peasy'
import { components } from 'Types/api'

export interface State {
  auth: object
  texts: Record<string, string>
  isLoading: boolean
  showWelcomeModal: boolean
  status: components['schemas']['Status']
}
export interface Actions {
  setAuth: Action<this, object>
  setTexts: Action<this, Record<string, string>>
  setIsLoading: Action<this, boolean>
  setShowWelcomeModal: Action<this, boolean>
  setStatus: Action<this, components['schemas']['Status']>
}
// eslint-disable-next-line
export interface Thunks {}

export interface GlobalModel extends State, Actions, Thunks {}

export const globalModel: GlobalModel = {
  auth: {},
  texts: {},
  isLoading: true,
  showWelcomeModal: false,
  status: {},

  // Actions
  setAuth: action((state, payload) => {
    state.auth = payload
  }),
  setTexts: action((state, payload) => {
    state.texts = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setShowWelcomeModal: action((state, payload) => {
    state.showWelcomeModal = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  })
}
