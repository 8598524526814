import { BaseController } from './BaseController'
import { components } from 'Types'

type DuoSignedRequest = components['schemas']['DuoSignedRequest']
export type DuoEnrollResponse = components['schemas']['DuoEnrollResponse']
export type DuoUserStatus = components['schemas']['DuoUserStatus']

export class DuoController extends BaseController {
  async getStatus (): Promise<DuoUserStatus> {
    const response = await this.useFetch('GET', 'duo/user_status', `${this.apiHost}/${this.apiPath}/register/duo/user_status`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getSignedUrl (): Promise<DuoSignedRequest> {
    const response = await this.useFetch('GET', 'duo/signed_url', `${this.apiHost}/${this.apiPath}/register/duo/signed_request`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getEnroll (): Promise<DuoEnrollResponse> {
    const response = await this.useFetch('POST', 'duo/enroll', `${this.apiHost}/${this.apiPath}/register/duo/enroll`, {
      platform: 'generic smartphone'
    })

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getBarcodeImage (url: string): Promise<BinaryType> {
    const response = await this.useFetch('GET', 'duo/barcode', url)

    if (response.status === 200) {
      return response.blob()
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}
