import { BaseController } from './BaseController'

import { components } from 'Types'

type StatusResponse = components['schemas']['Status']
type KerbSuggestions = components['schemas']['KerbSuggestions']
type KerbAvailable = components['schemas']['KerbAvailable']
type KerbCreate = components['schemas']['KerbCreate']
type Text = components['schemas']['Text']

export class AccountController extends BaseController {
  async getStatus (): Promise<StatusResponse> {
    const response = await this.useFetch('GET', 'status', `${this.apiHost}/${this.apiPath}/register/status`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async getTexts (): Promise<Record<string, string>> {
    const response = await this.useFetch('GET', 'text', `${this.apiHost}/${this.apiPath}/register/text`)

    if (response.status === 200) {
      const data: any = await response.json()
      const formattedData: Record<string, string> = data.reduce((acc: Record<string, string>, item: Text) => {
        return { ...acc, [item.textId ?? '']: item.text }
      }, {})
      return formattedData
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async resendToken (emailAddress: string): Promise<KerbSuggestions> {
    const response = await this.useFetch('POST', 'token/email', `${this.apiHost}/${this.apiPath}/register/token/email`, {
      emailAddress
    })

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async getKerbSuggestions (): Promise<KerbSuggestions> {
    const response = await this.useFetch('GET', 'kerb/suggest', `${this.apiHost}/${this.apiPath}/register/kerb/suggest`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async getKerbValid (krbName: string): Promise<boolean> {
    const response = await this.useFetch('GET', 'kerb/available', `${this.apiHost}/${this.apiPath}/register/kerb/available?krbName=${krbName}`)

    if (response.status === 200) {
      const data: KerbAvailable = await response.json()
      return data.available ?? false
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async createKerb (krbName: string): Promise<KerbCreate> {
    const response = await this.useFetch('POST', 'kerb', `${this.apiHost}/${this.apiPath}/register/kerb`, {
      krbName
    })

    if (response.status === 201) {
      const data = response.json()
      return data
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async createPassword (password: string): Promise<boolean> {
    const response = await this.useFetch('POST', 'password', `${this.apiHost}/${this.apiPath}/register/password`, {
      password
    })

    if (response.status === 201) {
      // const data = response.json()
      return true
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async uploadProfilePicture (image: Blob): Promise<boolean> {
    const response = await this.useFetch('POST', 'picture', `${this.apiHost}/${this.apiPath}/register/picture`, image, true)

    if (response.status === 200) {
      // const data = response.json()
      return true
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async resetTestUser (mitId: string): Promise<boolean> {
    const response = await this.useFetch('POST', 'test/reset', `${this.apiHost}/${this.apiPath}/test/reset`, {
      mitId
    })

    if (response.status === 200) {
      // const data = response.json()
      return true
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }
}
