import { AuthProvider, usePrimaryRegionConfig } from '../authorization'

const appConfig = usePrimaryRegionConfig()

export class BaseController {
  readonly apiHost: string
  readonly apiPath: string
  readonly apiPathDigitalId: string
  fetchController: Record<string, any>

  constructor () {
    this.apiHost = `https://${appConfig.api.domain}`
    this.apiPath = appConfig.api.paths.account
    this.apiPathDigitalId = appConfig.api.paths['digital-id']
    this.fetchController = {}
  }

  async getHeaders (method: string, file: boolean = false, impersonateKerb: string = ''): Promise<Headers> {
    const token = sessionStorage.getItem('AccessToken') ?? ''

    const headers: Headers = new Headers()
    headers.append('Authorization', token)

    if (file) {
      headers.append('Content-Type', 'image/png')
    } else {
      headers.append('Content-Type', 'application/json')
    }

    if (impersonateKerb !== '') {
      headers.append('X-Impersonated-User', impersonateKerb)
    }

    return headers
  }

  async useFetch (
    method: string,
    fetchKey: string,
    url: string,
    body: any = null,
    file: boolean = false,
    impersonateKerb: string = ''
  ): Promise<any> {
    if (this.fetchController[fetchKey] !== null && this.fetchController[fetchKey] !== undefined) {
      this.fetchController[fetchKey].abort()
    }

    this.fetchController[fetchKey] = new AbortController()
    const { signal } = this.fetchController[fetchKey]

    const options: any = {
      method: method,
      headers: await this.getHeaders(method, file, impersonateKerb),
      signal
    }
    if (body !== null) {
      options.body = file ? body : JSON.stringify(body)
    }

    const response = await fetch(url, options)

    const refereshToken = response.headers.get('x-authorization-refresh')
    if (refereshToken !== null) {
      sessionStorage.setItem('AccessToken', refereshToken)
    }

    // clear out controller
    this.fetchController[fetchKey] = null

    return response
  }

  async logout (): Promise<void> {
    await new AuthProvider().logout()
  }
}
