/* eslint-disable @typescript-eslint/no-extraneous-class */
import Amplify, { Auth } from 'aws-amplify'
import { FederatedSignInOptionsCustom } from '@aws-amplify/auth/lib/types'
import { usePrimaryRegionConfig } from 'Api/authorization/RegionConfig'
import { RegionalConfig } from 'Types'

export class AuthProvider {
  configure = async (): Promise<void> => {
    const config: RegionalConfig = usePrimaryRegionConfig()
    Amplify.configure(config.amplify)
  }

  getToken = async (): Promise<string> => {
    const config: RegionalConfig = usePrimaryRegionConfig()

    try {
      const response = await Auth.currentAuthenticatedUser()
      return response.getSignInUserSession().accessToken.jwtToken
    } catch (error) {
      const options: FederatedSignInOptionsCustom = {
        customProvider: config.signInProvider
      }
      await Auth.federatedSignIn(options)
      return ''
    }
  }

  logout = async (): Promise<void> => {
    console.debug('calling Auth.signOut()')
    await Auth.signOut()
  }
}
