// eslint-disable
import appConfig from '../../app-config'
import { RegionalConfig } from 'Types'
import { ActiveRegion, AtlasConfig } from '@mit/atlas-config'
import { getStage } from 'Common'
import { AuthProvider } from './AuthProvider'
import { ThunkCreator } from 'easy-peasy'

export const usePrimaryRegionConfig = (): RegionalConfig => {
  const regionConfig: ActiveRegion = JSON.parse(sessionStorage.getItem('ActiveRegion') as string)
  if (regionConfig === null) {
    console.error('No Regional Config Loaded. Default to Primary Region')
    return (appConfig.regions[0] as Record<string, any>) as RegionalConfig
  }

  const isPrimaryRegion: boolean = regionConfig.activeRegion === 'primary'

  // eslint-disable-next-line
  let config =
    appConfig.regions.length > 0
      ? appConfig.regions.filter(region => (isPrimaryRegion ? region.isPrimaryRegion : !region.isPrimaryRegion))[0]
      : appConfig.regions[0]

  config.amplify.oauth.domain = regionConfig.authenticationUrl.replace('https://', '')
  config.api.domain = regionConfig.apiUrl.replace('https://', '')
  console.log(config)
  return (config as Record<string, any>) as RegionalConfig
}

export const initializeActiveRegion = async (
  checkRegionUpdate: ThunkCreator<{ config?: ActiveRegion | undefined, hasHttpError?: boolean | undefined }, any>
): Promise<void> => {
  const config: ActiveRegion = await getActiveRegion()
  sessionStorage.setItem('ActiveRegion', JSON.stringify(config))
  console.log('RegionConfig', config)
  const authProvider = new AuthProvider()
  await authProvider.configure()
  checkRegionUpdate({ config })
}

export const getActiveRegion = async (): Promise<ActiveRegion> => {
  const config: ActiveRegion = await AtlasConfig.forStage(getStage()).getActiveRegion()
  return config
}
