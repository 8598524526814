import { Button, ButtonState, ButtonType } from '@mit/hui'
import React from 'react'
import { Resend } from './token'

interface LoaderProps {
  type: 'no-access' | 'expired'
  isLoading?: boolean
}

export const Loader: React.FC<LoaderProps> = ({ type, isLoading }) => {
  const getContent = (): React.ReactNode => {
    if (isLoading === true) return <Button type={ButtonType.Ghost} isBusy text='' state={ButtonState.Disabled} />

    if (type === 'no-access') {
      return (
        <>
          <strong>Access Denied</strong>
          <p>New account registrations only</p>
        </>
      )
    }

    if (type === 'expired') return <Resend />
  }

  return (
    <div className='loader-page resend'>
      <div className='logo-container '>
        <svg width='101px' height='52px' viewBox='0 0 101 52' version='1.1' xmlns='http://www.w3.org/2000/svg'>
          <title>Group 2</title>
          <g id='Atlas-Apps-Onboarding-OLD' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
            <g id='Splash-Screen' transform='translate(-63.000000, -183.000000)'>
              <g id='MIT' transform='translate(63.000000, 180.000000)'>
                <g id='Group-2' transform='translate(0.000000, 3.000000)'>
                  <polygon id='Fill-2' fill='#FFFFFF' points='54 10 65 10 65 0 54 0' />
                  <polygon id='Fill-3' fill='#FFFFFF' points='72 52 83 52 83 16 72 16' />
                  <polygon id='Fill-4' fill='#FFFFFF' points='0 52 11 52 11 0 0 0' />
                  <polygon id='Fill-5' fill='#FFFFFF' points='36 52 47 52 47 0 36 0' />
                  <polygon id='Fill-6' fill='#FFFFFF' points='18 36 29 36 29 0 18 0' />
                  <polygon id='Fill-7' fill='#FFFFFF' points='72 10 101 10 101 0 72 0' />
                  <polygon id='Fill-42' fill='#C2C0BF' points='54 52 65 52 65 16 54 16' />
                </g>
              </g>
            </g>
          </g>
        </svg>
        {/* <div className='fade-in'>Registration</div> */}
        <br />
        {getContent()}
      </div>
    </div>
  )
}
