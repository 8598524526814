import { CounterModel, counterModel } from './counter'
import { GlobalModel, globalModel } from './global'
import { ProfileModel, profileModel } from './profile'
import { RegionModel, regionModel } from './region'
import { StepModel, stepModel } from './step'

export interface Model {
  counterModel: CounterModel
  globalModel: GlobalModel
  profileModel: ProfileModel
  stepModel: StepModel
  regionModel: RegionModel
}

export const model: Model = {
  counterModel,
  globalModel,
  profileModel,
  stepModel,
  regionModel
}
