import { Button, ButtonState, Textbox } from '@mit/hui'
import { AccountController } from 'Api/controller'
import { BannerMessage } from 'Components/form'
import React, { useState } from 'react'
import { useStoreState } from 'Store'
import { Banner } from 'Types'

export const Resend: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [banner, setBanner] = useState<Banner>(null)
  const { texts } = useStoreState(state => state.globalModel)

  const handleSubmit = (): void => {
    setBanner(null)
    if (validateEmail()) {
      setIsLoading(true)
      const accountController = new AccountController()
      accountController
        .resendToken(email)
        .then(data => {
          setIsLoading(false)
          setBanner({ text: 'Check your email for an updated registration link', type: 'success' })
        })
        .catch(error => {
          console.error(error)
          setIsLoading(false)
          setBanner({ text: error, type: 'error' })
        })
    } else {
      setBanner({ text: 'Invalid email address', type: 'error' })
    }
  }

  const validateEmail = (): boolean => {
    let isValid = true
    let regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/

    if (texts['regex.email'] !== undefined) {
      regex = new RegExp(texts['regex.email'])
    }

    isValid = regex.test(email)
    return isValid
  }

  return (
    <div className='resend-container slide-in-elliptic-top-fwd'>
      <BannerMessage banner={banner} />
      <h2>Your secure link has expired</h2>
      <p>Please let us know where to send you a refreshed link.</p>
      <br />
      <p>Email me the link again at</p>
      <Textbox name='form-control' placeholderText='Email address to resend link to' onChange={(event: any) => setEmail(event?.target.value)} />
      <br />
      <div className='d-flex justify-content-center'>
        <Button
          text='Resend Link'
          icon='paper-plane'
          isBusy={isLoading}
          onClick={handleSubmit}
          state={email !== '' ? ButtonState.Enabled : ButtonState.Disabled}
        />
      </div>
    </div>
  )
}
