import appConfig from '../app-config'

type EnvReturn = 'dev' | 'staging' | 'production'

type StageReturn = 'develop' | 'release' | 'master'

export const getEnv = (): EnvReturn => {
  switch (getStage()) {
    case 'develop':
      return 'dev'
    case 'release':
      return 'staging'
    case 'master':
      return 'production'
  }
}

export const getStage = (): StageReturn => {
  return appConfig.stage as StageReturn
}
